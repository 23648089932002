import React, { useContext, useState } from "react"
import { PEPS_URL } from "config/env"
import RootStore from "stores/RootStore"
import { observer } from "mobx-react"
import PepsModal from "components/PepsModal/PepsModal.component"

const PepsLoginModal: React.FC = () => {
  const { statusStore } = useContext(RootStore)
  const [forceHideBeforeNextLogin, setForceHideBeforeNextLogin] =
    useState(false)

  function onLoginMessageEvent(event: MessageEvent): void {
    if (event.data.result === "logged in") {
      // This will set loginToPepsRequested to false
      statusStore.pepsUserHasLoggedIn(
        event.data.user,
        event.data.identificationToken,
      )
      setForceHideBeforeNextLogin(true)
    }
  }

  // Force hide for one tick to make sure that login view will refresh.
  if (forceHideBeforeNextLogin && statusStore.loginToPepsRequested) {
    setTimeout(() => {
      setForceHideBeforeNextLogin(false)
    }, 0)
  }

  return forceHideBeforeNextLogin && statusStore.loginToPepsRequested ? null : (
    <PepsModal
      url={`${PEPS_URL}/embedded/login`}
      visible={statusStore.loginToPepsRequested}
      onMessage={onLoginMessageEvent}
      onCancel={() => statusStore.closeLoginToPepsWindow()}
      destroyOnClose={true}
    />
  )
}

export default observer(PepsLoginModal)
