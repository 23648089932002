import React from "react"
import { Patient } from "../../../models/Patient"
import { Tag } from "antd"
import { observer } from "mobx-react"

interface PatientsListPepsColumnProps {
  patient: Patient
}

const PatientsListPepsColumn: React.FC<PatientsListPepsColumnProps> = ({
  patient,
}) => {
  return patient.pepsRegistrationCompleted ? (
    <Tag color="success">Registered</Tag>
  ) : patient.pepsId ? (
    <Tag color="processing">Registration initiated</Tag>
  ) : (
    <Tag>Not registered</Tag>
  )
}

export default observer(PatientsListPepsColumn)
