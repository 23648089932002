import React, { useContext } from "react"
import { Card, Typography, Spin, Button, Result } from "antd"
import { observer } from "mobx-react"
import RootStore from "stores/RootStore"
import PatientsList from "components/PatientsList/PatientsList.component"
import PepsApi from "services/PepsApi/PepsApi.service"

const { Title } = Typography

const Patients: React.FC = () => {
  const { patientsStore, statusStore } = useContext(RootStore)

  if (
    statusStore.pepsUser &&
    !patientsStore.error &&
    !patientsStore.patients &&
    !patientsStore.isFetching
  ) {
    if (statusStore.pepsUser.role === "DOCTOR") {
      patientsStore.fetchOwnPatients().then(/* noop */)
    } else {
      patientsStore.fetchAllPatients().then(/* noop */)
    }
  }

  if (patientsStore.error) {
    return (
      <Result
        status="error"
        title={patientsStore.error}
        subTitle="Please try refreshing the application."
        extra={
          <>
            <Button type="primary" href="/">
              Refresh
            </Button>

            <Button type="primary" onClick={() => PepsApi.logout()}>
              Try logging in again
            </Button>
          </>
        }
      />
    )
  }

  if (!patientsStore.patients) {
    return (
      <div className="text-center m-32">
        <Spin tip="Loading list..." />
      </div>
    )
  }

  return (
    <div className="px-12 pt-4">
      <Card>
        <Title level={2}>Patients</Title>
        <PatientsList patients={patientsStore.patients} />
      </Card>
    </div>
  )
}

export default observer(Patients)
