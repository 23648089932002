import React from "react"
import { Modal, Typography } from "antd"

const { Link, Text, Paragraph } = Typography

class Modals {
  static showSuccess(message: string, details?: string): void {
    Modal.success({
      title: message,
      content: details ? <Paragraph>{details}</Paragraph> : undefined,
    })
  }

  static showClarumConnectionError(message: string): void {
    Modal.error({
      title: "Cannot connect to Clarum",
      content: (
        <>
          <Paragraph>
            Message:
            <br />
            <Text code>{message || "<No message>"}</Text>
          </Paragraph>
          <Paragraph>
            Click <Link href="/">here</Link> to refresh the application.
          </Paragraph>
        </>
      ),
    })
  }

  static showPepsConnectionError(message: string): void {
    Modal.error({
      title: "Cannot connect to PEPS",
      content: (
        <>
          <Paragraph>
            Message:
            <br />
            <Text code>{message || "<No message>"}</Text>
          </Paragraph>
          <Paragraph>
            Click <Link href="/">here</Link> to refresh the application.
          </Paragraph>
        </>
      ),
    })
  }

  static showClarumLoginError(): void {
    Modal.error({
      title: "Cannot log in into Clarum service",
      content: <Paragraph>Please contact the administrator.</Paragraph>,
      okText: "Refresh app",
      onOk: () => window.location.reload(),
    })
  }

  static showClarumCommunicationError(message: string): void {
    Modal.error({
      title: "Error with Clarum service communication",
      content: (
        <Paragraph>
          Details:
          <br />
          <Text code>{message || "<No message>"}</Text>
        </Paragraph>
      ),
    })
  }

  static showDataStructureError(message: string): void {
    Modal.error({
      title: "Incorrect data structure in Clarum",
      content: (
        <>
          <Paragraph>
            Message:
            <br />
            <Text code>{message || "<No message>"}</Text>
          </Paragraph>
          <Paragraph>
            Please contact with the administrator of Clarum.
          </Paragraph>
        </>
      ),
    })
  }

  static showError(message: string, error?: string): void {
    const isHTML = error && error.substr(0, 15) === "<!DOCTYPE html>"
    Modal.error({
      title: message,
      content:
        error &&
        (isHTML ? (
          <iframe title="error" style={{ height: 50 }} srcDoc={error} />
        ) : (
          <Paragraph>
            <Text code>{error}</Text>
          </Paragraph>
        )),
    })
  }
}

export default Modals
