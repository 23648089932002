import React, { useContext } from "react"
import { Layout, Menu, Dropdown, Space, Button, Divider } from "antd"
import { Link } from "react-router-dom"
import {
  CLARUM_WEBSITE_DOCTOR,
  CLARUM_WEBSITE_ADMIN,
  PEPS_URL,
} from "config/env"
import { ApiOutlined, LinkOutlined, LogoutOutlined } from "@ant-design/icons"
import PepsApi from "services/PepsApi/PepsApi.service"
import PepsAvatar from "components/PepsAvatar/PepsAvatar.component"
import RootStore from "stores/RootStore"
import { observer } from "mobx-react"

const { Header } = Layout

const AppHeader: React.FC = () => {
  const { statusStore } = useContext(RootStore)

  const isDoctor =
    statusStore.pepsUser && statusStore.pepsUser.role === "DOCTOR"
  const clarumWebsiteLink = isDoctor
    ? CLARUM_WEBSITE_DOCTOR
    : CLARUM_WEBSITE_ADMIN

  const clarumMenu = (
    <Menu>
      <Menu.Item key="dashboard">
        <a href={clarumWebsiteLink} target="_blank" rel="noopener noreferrer">
          <LinkOutlined /> Dashboard
        </a>
      </Menu.Item>
      <Menu.Item key="divider-1">
        <Divider className="m-0" />
      </Menu.Item>
      <Menu.Item key="patients">
        <a
          href={`${clarumWebsiteLink}/patients/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkOutlined /> Patients
        </a>
      </Menu.Item>
    </Menu>
  )

  const pepsMenu = (
    <Menu>
      <Menu.ItemGroup title="Messages">
        <Menu.Item key="inbox">
          <a
            href={`${PEPS_URL}/messages/incoming`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkOutlined /> Inbox
          </a>
        </Menu.Item>
        <Menu.Item key="sent">
          <a
            href={`${PEPS_URL}/messages/sent`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkOutlined /> Sent
          </a>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const accountMenu = statusStore.pepsUser && (
    <Menu>
      <Menu.ItemGroup title={`Logged in as ${statusStore.pepsUser.role}`}>
        <Menu.Item key="logout" onClick={() => PepsApi.logout()}>
          <Button type="text">
            <LogoutOutlined /> Logout
          </Button>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  return (
    <Header className="select-none">
      <Link to="/" className="text-2xl font-bold text-white mr-8">
        <ApiOutlined />
        &nbsp; Clarum-PEPS
      </Link>

      <Space className="float-right">
        <Dropdown overlay={clarumMenu}>
          <Button type="text" className="text-white">
            <img
              src="/img/clarum-logo.svg"
              alt="Clarum"
              style={{ height: "1.3em" }}
            />
          </Button>
        </Dropdown>

        <Dropdown overlay={pepsMenu}>
          <Button type="text" className="text-white">
            <img
              src="/img/peps-logo.svg"
              alt="PEPS"
              style={{ height: "1em" }}
            />
          </Button>
        </Dropdown>

        {statusStore.pepsUser && (
          <Dropdown overlay={accountMenu!}>
            <Button type="text" className="text-white">
              <PepsAvatar seed={statusStore.pepsUser.avatar} />
            </Button>
          </Dropdown>
        )}
      </Space>
    </Header>
  )
}

export default observer(AppHeader)
