import React from "react"
import { configure } from "mobx"
import PatientsStore from "./PatientsStore/PatientsStore"
import StatusStore from "./StatusStore/StatusStore"

configure({ enforceActions: "observed" })

export const stores = {
  statusStore: new StatusStore(),
  patientsStore: new PatientsStore(),
}

const RootStore = React.createContext(stores)

export default RootStore
