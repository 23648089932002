import React, { useState } from "react"
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { Typography } from "antd"

const { Link } = Typography

const HiddenText: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      {visible ? (
        <>
          {children}&nbsp;
          <Link onClick={() => setVisible(false)}>
            <EyeInvisibleOutlined />
          </Link>
        </>
      ) : (
        <>
          ****&nbsp;
          <Link onClick={() => setVisible(true)}>
            <EyeOutlined />
          </Link>
        </>
      )}
    </>
  )
}

export default HiddenText
