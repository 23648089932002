export default class TypeUtils {
  static recognizeNonNullObject(obj: unknown): obj is {} {
    return typeof obj === "object" && obj !== null
  }

  static recognizeProperty<X extends {}, Y extends PropertyKey>(
    obj: X,
    prop: Y,
  ): obj is X & Record<Y, unknown> {
    return Object.prototype.hasOwnProperty.call(obj, prop)
  }
}
