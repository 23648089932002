import { observable, action, makeObservable } from "mobx"
import PepsApi from "services/PepsApi/PepsApi.service"
import Modals from "services/Modals/Modals"
import { Role } from "../../../models/Role"

type PepsUser = {
  avatar: string
  role: Role
}

class StatusStore {
  @observable pepsUser: PepsUser | null = null
  @observable loginToPepsRequested = false
  @observable logoutFromPepsRequested = false
  @observable retryLoginRequested = false

  constructor() {
    makeObservable(this)

    this.loadPepsUser()

    PepsApi.checkStatus().catch((error) => {
      Modals.showPepsConnectionError(error.message)
    })
  }

  @action
  loadPepsUser(): void {
    const pepsUserJSON = window.localStorage.getItem("pepsUser")
    const token = window.localStorage.getItem("identificationToken")
    if (pepsUserJSON && token) {
      this.pepsUser = JSON.parse(pepsUserJSON)
    }
  }

  @action
  pepsUserHasLoggedIn(pepsUser: PepsUser, token: string): void {
    window.localStorage.setItem("pepsUser", JSON.stringify(pepsUser))
    window.localStorage.setItem("identificationToken", token)

    this.pepsUser = pepsUser
    if (this.loginToPepsRequested) {
      // Setting this to false to in one action to avoid race condition.
      this.loginToPepsRequested = false
    }
    if (this.retryLoginRequested) {
      // Setting this to false to in one action to avoid race condition.
      this.retryLoginRequested = false
    }
  }

  @action
  pepsUserHasLoggedOut(): void {
    this.pepsUser = null
    window.localStorage.removeItem("pepsUser")
    window.localStorage.removeItem("identificationToken")
  }

  @action
  requestLoginToPeps(): void {
    this.loginToPepsRequested = true
    if (this.pepsUser) {
      // Token might be invalid. Force to re-login.
      this.pepsUser = null
    }
  }

  @action
  closeLoginToPepsWindow(): void {
    this.loginToPepsRequested = false
    this.retryLoginRequested = false
  }

  @action requestLogoutFromPeps(): void {
    this.logoutFromPepsRequested = true
  }

  @action closeLogoutFromPepsWindow(): void {
    this.logoutFromPepsRequested = false
  }

  @action requestRetryLogin(): void {
    this.retryLoginRequested = true
    this.loginToPepsRequested = true
  }
}

export default StatusStore
