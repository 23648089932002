import React, { useContext } from "react"
import { Patient } from "../../../models/Patient"
import { Input, Space, Table } from "antd"
import { ColumnsType, ExpandableConfig } from "antd/lib/table/interface"
import PatientsListDetails from "components/PatientsListDetails/PatientsListDetails.component"
import PatientsListPepsColumn from "components/PatientsListPepsColumn/PatientsListPepsColumn.component"
import PatientsListSearchByName from "components/PatientsListSearchByName/PatientsListSearchByName.component"
import PatientsListRefreshStatuses from "components/PatientsListRefreshStatuses/PatientsListRefreshStatuses.component"
import SyncRepliesButton from "components/SyncRepliesButton/SyncRepliesButton.component"
import { SearchOutlined } from "@ant-design/icons"
import StringUtils from "services/StringUtils/StringUtils.service"
import RootStore from "stores/RootStore"
import { observer } from "mobx-react"

interface PatientsListProps {
  patients: Patient[] | undefined
}

let searchInputRef: Input | undefined

const columns: ColumnsType<Patient> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        if (!a.name || !b.name) {
          return 0
        }
        return a.name.localeCompare(b.name)
      },
    },
    sortDirections: ["ascend"],
    filterDropdown: (options) => (
      <PatientsListSearchByName
        options={options}
        onCreated={(ref) => (searchInputRef = ref)}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      (record.name && StringUtils.search(value as string, record.name)) ||
      false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef && searchInputRef.select(), 0)
      }
    },
  },
  {
    title: "Patient ID",
    dataIndex: "id",
    key: "id",
    width: 150,
  },
  {
    title: "PEPS",
    key: "peps",
    render: (patient: Patient) => <PatientsListPepsColumn patient={patient} />,
    width: 100,
    filters: [
      {
        text: "Registered",
        value: "registered",
      },
      {
        text: "Registration initiated",
        value: "registration-initiated",
      },
      {
        text: "Not registered",
        value: "not-registered",
      },
    ],
    onFilter: (value, record) => {
      if (value === "registered") return !!record.pepsRegistrationCompleted
      if (value === "not-registered") return !record.pepsId
      return !!record.pepsId && !record.pepsRegistrationCompleted
    },
    filterMultiple: false,
  },
]

const expandable: ExpandableConfig<Patient> = {
  expandedRowRender: (patient) => <PatientsListDetails patient={patient} />,
}

const PatientsList: React.FC<PatientsListProps> = ({ patients }) => {
  const { statusStore } = useContext(RootStore)

  const isAdmin = statusStore.pepsUser && statusStore.pepsUser.role === "ADMIN"

  let filteredColumns = columns
  if (statusStore.pepsUser && isAdmin) {
    filteredColumns = [...columns]
    filteredColumns.shift()
    filteredColumns[0] = { ...filteredColumns[0], width: undefined }
  }

  return (
    <Space direction="vertical" className="w-full">
      <Space className="float-right">
        All patients:
        {isAdmin && <SyncRepliesButton patients={patients} />}
        <PatientsListRefreshStatuses patients={patients} />
      </Space>

      <Table
        dataSource={patients}
        columns={filteredColumns}
        expandable={expandable}
        rowKey="id"
      />
    </Space>
  )
}

export default observer(PatientsList)
