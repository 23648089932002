import React from "react"
import { Result } from "antd"
import { Link } from "react-router-dom"

const NotFound: React.FC = () => {
  return (
    <Result
      status="error"
      title="404"
      subTitle="This page doesn't exist."
      extra={<Link to="/">go to the homepage</Link>}
    />
  )
}

export default NotFound
