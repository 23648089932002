import React, { useContext } from "react"
import { PEPS_URL } from "config/env"
import RootStore from "stores/RootStore"
import { observer } from "mobx-react"
import PepsModal from "components/PepsModal/PepsModal.component"

const PepsLogoutModal: React.FC = () => {
  const { statusStore } = useContext(RootStore)

  function onLogoutMessageEvent(event: MessageEvent): void {
    if (event.data.result === "logged out") {
      statusStore.closeLogoutFromPepsWindow()
      window.location.reload()
    }
  }
  return (
    <PepsModal
      url={`${PEPS_URL}/embedded/logout`}
      visible={statusStore.logoutFromPepsRequested}
      onMessage={onLogoutMessageEvent}
      onCancel={() => statusStore.closeLogoutFromPepsWindow()}
      destroyOnClose={true}
    />
  )
}

export default observer(PepsLogoutModal)
