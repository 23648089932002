import localeCharacters from "config/locale-characters.json"

class StringUtils {
  static cleanString(text: string): string {
    return text.replace(/[^A-Za-z0-9[\] ]/g, (a): string => {
      return (localeCharacters as { [key: string]: string })[a] || a
    })
  }

  static search(phrase: string, subjectString: string): boolean {
    const cleanPhrase = StringUtils.cleanString(phrase).toLowerCase()
    const cleanSubjectString =
      StringUtils.cleanString(subjectString).toLowerCase()

    const phraseParts = cleanPhrase.split(" ")
    for (let i = 0; i < phraseParts.length; i++) {
      if (!cleanSubjectString.includes(phraseParts[i])) return false
    }

    return true
  }
}

export default StringUtils
