import React from "react"
import ReactDOM from "react-dom"
import "./tailwind-generated.css"
import * as serviceWorker from "./serviceWorker"
import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { messages } from "./locales/en/messages"
import { en } from "make-plural/plurals"
import RootStore, { stores } from "./stores/RootStore"
import App from "./App.component"

i18n.loadLocaleData("en", { plurals: en })
i18n.load("en", messages)
i18n.activate("en")

const I18nWrapper: React.FC = () => {
  return (
    <I18nProvider i18n={i18n}>
      <App />
    </I18nProvider>
  )
}

ReactDOM.render(
  <RootStore.Provider value={stores}>
    <I18nWrapper />
  </RootStore.Provider>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
