import React, { Suspense, useContext } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import AppFooter from "components/AppFooter/AppFooter.component"
import { Layout, Spin } from "antd"
import styled from "styled-components"
import NotFound from "pages/NotFound/NotFound.page"
import AppHeader from "components/AppHeader/AppHeader.component"
import Patients from "pages/Patients/Patients.page"
import PepsLoginModal from "components/PepsLoginModal/PepsLoginModal.component"
import PepsLogoutModal from "components/PepsLogoutModal/PepsLogoutModal.component"
import PepsApi from "services/PepsApi/PepsApi.service"
import RootStore from "stores/RootStore"

const { Content } = Layout

const StyledLayout = styled(Layout)`
  min-width: 600px;
`

const App: React.FC = () => {
  const { patientsStore, statusStore } = useContext(RootStore)

  // init once
  PepsApi.init(patientsStore, statusStore)

  return (
    <BrowserRouter>
      <StyledLayout>
        <AppHeader />
        <Layout>
          <Content>
            <Suspense
              fallback={
                <div className="text-center pt-12">
                  <Spin />
                </div>
              }
            >
              <Switch>
                <Route path="/" component={Patients} />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </Content>
        </Layout>
        <AppFooter />

        <PepsLoginModal />
        <PepsLogoutModal />
      </StyledLayout>
    </BrowserRouter>
  )
}

export default App
