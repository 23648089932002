import React from "react"
import { Layout, Typography } from "antd"
import { FOOTER_HTML } from "config/env"

const { Footer } = Layout
const { Paragraph } = Typography

const AppFooter: React.FC = () => {
  return (
    <Footer className="text-center select-none">
      <Paragraph>
        Copyright &copy; Roche. All rights reserved.
        <br />
        <div dangerouslySetInnerHTML={{ __html: FOOTER_HTML }} />
      </Paragraph>
    </Footer>
  )
}

export default AppFooter
