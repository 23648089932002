import React, { useEffect, useRef, useState } from "react"
import { Button, Modal, Tree, Typography } from "antd"
import { CheckOutlined, SwapOutlined, SyncOutlined } from "@ant-design/icons"
import { DataNode } from "rc-tree/lib/interface"
import BackEndApi from "services/BackEndApi/BackEndApi.service"
import { Reply } from "../../../models/Reply"
import Modals from "services/Modals/Modals"
import {
  generateQuestionsDataForTree,
  prepareSelectedMessagesList,
} from "./SyncRepliesModalUtils"
import SyncRepliesInstructionModal from "../SyncRepliesInstructionModal/SyncRepliesInstructionModal"
import { ClarumURLs, InstructionData } from "../../../models/InstructionData"
import TypeUtils from "../../services/TypeUtils/TypeUtils.service"

const { Paragraph, Text } = Typography

const ACTION_COMPLETE_TIMEOUT = 2000

interface SyncRepliesProps {
  visible: boolean
  onClose: (e: React.MouseEvent<HTMLElement>) => void
}

const SyncRepliesModal: React.FC<SyncRepliesProps> = ({ visible, onClose }) => {
  const [questionsData, setQuestionsData] = useState<DataNode[] | undefined>()
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const [isSynchronizing, setIsSynchronizing] = useState(false)
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([])
  const [isInstructionVisible, setIsInstructionVisible] =
    useState<boolean>(false)
  const [instructionData, setInstructionData] = useState<
    InstructionData | undefined
  >()
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  async function refresh(): Promise<void> {
    setIsRefreshing(true)
    try {
      const replies: Reply[] = await BackEndApi.getLatestReplies()
      if (!mounted.current) return
      if (replies.length) {
        setQuestionsData(generateQuestionsDataForTree(replies))
      } else {
        setQuestionsData([])
      }

      setRefreshed(true)
      setTimeout(() => {
        if (mounted.current) {
          setRefreshed(false)
        }
      }, ACTION_COMPLETE_TIMEOUT)
    } catch (error) {
      if (mounted.current) {
        setQuestionsData([])
      }
      const message = TypeUtils.recognizeNonNullObject(error)
        ? error.toString()
        : "unknown error"
      Modals.showError("Cannot get latest replies", message)
    }

    setIsRefreshing(false)
  }

  if (visible && !questionsData && !isRefreshing) {
    refresh()
  }

  async function synchronize(e: React.MouseEvent<HTMLElement>): Promise<void> {
    setIsInstructionVisible(false)
    setIsSynchronizing(true)

    try {
      const messagesInfo = prepareSelectedMessagesList(checkedKeys as string[])
      await BackEndApi.transferReplies(messagesInfo)
      Modals.showSuccess("All replies has been transferred.")
      if (!mounted.current) return
      onClose(e)
    } catch (error) {
      if (
        !TypeUtils.recognizeNonNullObject(error) ||
        !TypeUtils.recognizeProperty(error, "code")
      ) {
        Modals.showError("Incorrect response", "unknoww error")
        return
      }
      if (error.code === 409) {
        if (!TypeUtils.recognizeProperty(error, "data")) {
          Modals.showError("Incorrect response", "missing data field")
          return
        }
        const data = error.data
        if (
          !TypeUtils.recognizeNonNullObject(data) ||
          !TypeUtils.recognizeProperty(data, "incorrectClarumFields") ||
          !TypeUtils.recognizeProperty(data, "clarumURLs") ||
          !TypeUtils.recognizeProperty(data, "summary") ||
          !TypeUtils.recognizeProperty(
            data.clarumURLs as ClarumURLs,
            "pepsFrame",
          ) ||
          !TypeUtils.recognizeProperty(
            data.clarumURLs as ClarumURLs,
            "apiSettings",
          )
        ) {
          const message = TypeUtils.recognizeProperty(error, "message")
            ? error.toString()
            : "unknown error"
          Modals.showError("Incorrect response", message)
        } else {
          if (mounted.current) {
            setInstructionData(data as InstructionData)
            setIsInstructionVisible(true)
          }
        }
      } else {
        const message = TypeUtils.recognizeProperty(error, "message")
          ? error.toString()
          : "unknown error"
        Modals.showError("Cannot transfer replies", message)
      }
    }

    if (mounted.current) {
      setIsSynchronizing(false)
    }
  }

  return (
    <>
      <Modal
        title="Sync replies"
        visible={visible}
        okText={
          <>
            <SwapOutlined /> Transfer data
          </>
        }
        onOk={synchronize}
        confirmLoading={isSynchronizing}
        okButtonProps={{ disabled: !checkedKeys.length }}
        onCancel={onClose}
      >
        <Paragraph>
          Please select the questions for which you want to transfer the answers
          to Clarum.
        </Paragraph>
        {questionsData && questionsData.length ? (
          <Tree
            checkable
            selectable={false}
            defaultExpandedKeys={["all"]}
            treeData={questionsData}
            onCheck={(checked) => setCheckedKeys(checked as React.Key[])}
          />
        ) : (
          <Paragraph>
            <Text strong>No replies available yet.</Text>
          </Paragraph>
        )}
        <Button
          loading={isRefreshing}
          type="link"
          size="small"
          onClick={refresh}
          icon={refreshed ? <CheckOutlined /> : <SyncOutlined />}
        >
          Refresh list
        </Button>
      </Modal>

      {instructionData && (
        <SyncRepliesInstructionModal
          visible={isInstructionVisible}
          instructionData={instructionData}
          onRetry={(e) => synchronize(e)}
          onCancel={() => setIsInstructionVisible(false)}
        />
      )}
    </>
  )
}

export default SyncRepliesModal
