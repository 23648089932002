import React from "react"
import { Button, Divider, Input, Space } from "antd"
import { FilterDropdownProps } from "antd/lib/table/interface"
import { SearchOutlined } from "@ant-design/icons"

interface PatientsListSearchByNameProps {
  options: FilterDropdownProps
  onCreated: (searchInputRef: Input) => void
}

const PatientsListSearchByName: React.FC<PatientsListSearchByNameProps> = ({
  options,
  onCreated,
}) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters } = options

  return (
    <>
      <div className="p-2">
        <Input
          ref={onCreated}
          placeholder="Search name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
        />
      </div>
      <Divider className="m-0" />
      <div className="p-2">
        <Space>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            className="w-24"
            onClick={() => confirm()}
          >
            Search
          </Button>
          <Button
            size="small"
            className="w-24"
            onClick={() => clearFilters && clearFilters()}
          >
            Reset
          </Button>
        </Space>
      </div>
    </>
  )
}

export default PatientsListSearchByName
