import React, { useEffect, useState } from "react"
import { Modal } from "antd"
import PepsIframe from "components/PepsIframe/PepsIframe.component"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const MODAL_PADDING = 0 // default is 24

interface PepsModalProps {
  visible: boolean
  url: string
  onCancel: (e: Event) => void
  onMessage?: (e: MessageEvent) => void
  destroyOnClose?: boolean
}

function confirm(onOk: (e: MouseEvent) => void) {
  Modal.confirm({
    title: "Aborting PEPS action!",
    icon: <ExclamationCircleOutlined />,
    content: "Do you really want to close this modal?",
    onOk: onOk,
    okText: "Yes",
  })
}

const PepsModal: React.FC<PepsModalProps> = ({
  visible,
  url,
  onCancel,
  onMessage,
  destroyOnClose,
}) => {
  const [modalWidth, setModalWidth] = useState<number>()
  const [latestUrl, setLatestUrl] = useState<string>("")
  const [latestOnMessage, setLatestOnMessage] = useState<any>()

  useEffect(() => {
    if (visible) {
      // Prevent from updating iframe when not visible
      if (url !== latestUrl) setLatestUrl(url)
      if (onMessage !== latestOnMessage) setLatestOnMessage(() => onMessage)
    }
  }, [visible, url, latestUrl, onMessage, latestOnMessage])

  return (
    <Modal
      visible={visible}
      title="PEPS"
      footer={null}
      bodyStyle={{ padding: MODAL_PADDING }}
      onCancel={() => confirm(onCancel)}
      width={modalWidth}
      destroyOnClose={destroyOnClose}
    >
      <PepsIframe
        url={latestUrl}
        onContentWidthUpdate={(width) => {
          setModalWidth(width + 2 * MODAL_PADDING)
        }}
        onMessage={latestOnMessage}
      />
    </Modal>
  )
}

export default PepsModal
