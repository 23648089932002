import { observable, action, makeObservable } from "mobx"
import { Patient } from "../../../models/Patient"
import BackEndApi from "services/BackEndApi/BackEndApi.service"
import TypeUtils from "services/TypeUtils/TypeUtils.service"

class PatientsStore {
  @observable patients: Array<Patient> | undefined
  @observable isFetching = false
  @observable error: string | undefined

  constructor() {
    makeObservable(this)
  }

  @action
  fetchAllPatients = async () => {
    await this._fetchPatients(false)
  }

  @action
  fetchOwnPatients = async () => {
    await this._fetchPatients(true)
  }

  @action
  setPatients = (patients: Patient[] | undefined): void => {
    this.patients = patients ? observable(patients) : undefined
    this.isFetching = false
  }

  @action
  setIsFetching = (isFetching: boolean): void => {
    this.isFetching = isFetching
  }

  @action
  setError = (error: string): void => {
    this.error = error
  }

  @action
  reset = (): void => {
    this.patients = undefined
    this.isFetching = false
    this.error = undefined
  }

  @action
  refreshAllStatuses = async () => {
    await this._refreshStatuses(false)
  }

  @action
  refreshStatusesOfOwn = async () => {
    await this._refreshStatuses(true)
  }

  registerPatient = async (
    patient: Patient,
    pepsId: string,
    isCaregiver: boolean,
  ): Promise<void> => {
    const pepsFrameId = await BackEndApi.registerPatient(
      patient.id,
      pepsId,
      isCaregiver,
    )

    this.updatePatientInStore(patient, { pepsFrameId, pepsId, isCaregiver })
  }

  @action
  private updatePatientInStore = (
    patient: Patient,
    changes: Partial<Patient>,
  ): void => {
    if (!this.patients) return

    const index = this.patients.indexOf(patient)
    if (index === -1) return

    Object.assign(this.patients[index], changes)
  }

  private _fetchPatients = async (ownOnly: boolean) => {
    if (!this.isFetching) {
      this.isFetching = true
      this.error = undefined

      try {
        const patients: Patient[] = await BackEndApi.getPatients(ownOnly)
        this.setPatients(patients)
      } catch (error) {
        const message = TypeUtils.recognizeNonNullObject(error)
          ? error.toString()
          : "unknown error"
        this.setError(message)
        this.setIsFetching(false)
      }
    }
  }

  private _refreshStatuses = async (ownOnly: boolean): Promise<void> => {
    await BackEndApi.refreshStatuses(ownOnly).then((response) => {
      // User may log out due to token expiration.
      // Then, list of patients will be re-downloaded and refreshed automatically.
      if (!this.patients) return

      const pepsPatients: Patient[] = this.patients.filter(
        (patient) => patient.pepsId,
      )
      const indexedPepsPatients = new Map<string, Patient>(
        pepsPatients.map((patient) => [patient.pepsId as string, patient]),
      )

      response.forEach((result) => {
        if (result.status === "verified") {
          const patient = indexedPepsPatients.get(result.pepsId)
          if (patient) {
            this.updatePatientInStore(patient, {
              pepsRegistrationCompleted: true,
              avatar: result.avatar,
            })
          }
        }
        // if (newStatus === 'not registered') {
        //   Removing users will be not supported by PEPS in MVP, so this will never happen.
        // }
      })
    })
  }
}

export default PatientsStore
