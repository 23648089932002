import React, { useContext, useState } from "react"
import { Patient } from "../../../models/Patient"
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Typography,
} from "antd"
import { LinkOutlined } from "@ant-design/icons"
import { CLARUM_WEBSITE_DOCTOR, CLARUM_WEBSITE_ADMIN } from "config/env"
import { observer } from "mobx-react"
import HiddenText from "components/HiddenText/HiddenText.component"
import PepsModal from "components/PepsModal/PepsModal.component"
import PepsApi from "services/PepsApi/PepsApi.service"
import RootStore from "stores/RootStore"
import Modals from "services/Modals/Modals"
import PepsAvatar from "components/PepsAvatar/PepsAvatar.component"

const { Link, Text, Paragraph } = Typography

interface PatientsListDetailsProps {
  patient: Patient
}

const PatientsListDetails: React.FC<PatientsListDetailsProps> = ({
  patient,
}) => {
  const [registerModalVisible, setRegisterModalVisible] = useState(false)
  const { patientsStore, statusStore } = useContext(RootStore)

  const isDoctor =
    statusStore.pepsUser && statusStore.pepsUser.role === "DOCTOR"
  const clarumWebsiteLink = isDoctor
    ? CLARUM_WEBSITE_DOCTOR
    : CLARUM_WEBSITE_ADMIN

  const url = PepsApi.getRegisterFormUrl(patient)

  function onRegisterMessageEvent(event: MessageEvent): void {
    // user registered
    if (event.data.result === "user registered" && event.data.registeredUser) {
      patientsStore
        .registerPatient(
          patient,
          event.data.registeredUser.pepsId || "no-peps-id",
          event.data.registeredUser.isCaregiver,
        )
        .catch((error) => {
          Modals.showClarumCommunicationError(error.message)
        })
    }
  }

  return (
    <Row align="middle">
      <Col span={14}>
        <Descriptions
          title={
            <Space>
              <PepsAvatar size="small" seed={patient.avatar || undefined} />
              {isDoctor ? patient.name : "[Anonymized data]"}
            </Space>
          }
          size="small"
          column={{ xxl: 3, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="ID">{patient.id}</Descriptions.Item>
          {isDoctor ? (
            <Descriptions.Item>{null}</Descriptions.Item>
          ) : (
            <Descriptions.Item label="Doctor">
              <Link
                href={`${clarumWebsiteLink}/doctors/${
                  typeof patient.doctor === "string"
                    ? patient.doctor
                    : patient.doctor.id
                }/`}
                target="_blank"
              >
                {typeof patient.doctor === "string"
                  ? patient.doctor
                  : patient.doctor.name}{" "}
                <LinkOutlined />
              </Link>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Gender">{patient.gender}</Descriptions.Item>
          <Descriptions.Item label="Date of birth">
            {patient.dateOfBirth
              ? new Date(patient.dateOfBirth).toLocaleDateString()
              : "Not provided"}
          </Descriptions.Item>
          {isDoctor && (
            <>
              <Descriptions.Item label="Email">
                {patient.email || <Text type="secondary">Not provided</Text>}
              </Descriptions.Item>
              <Descriptions.Item label="Language" className="capitalize">
                {patient.language || <Text type="secondary">Not provided</Text>}
              </Descriptions.Item>
            </>
          )}
          <Descriptions.Item label="Clarum profile">
            <Link
              href={`${clarumWebsiteLink}/patients/${patient.id}/`}
              target="_blank"
            >
              See full profile <LinkOutlined />
            </Link>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={1} className="self-stretch">
        <Divider type="vertical" className="h-full" />
      </Col>
      <Col span={9} className="text-center">
        {patient.pepsId ? (
          <>
            <Descriptions size="small" column={{ md: 1 }}>
              <Descriptions.Item label="PEPS ID">
                <HiddenText>{patient.pepsId}</HiddenText>
              </Descriptions.Item>
              {patient.isCaregiver && (
                <Descriptions.Item>
                  Registered as a caregiver.
                </Descriptions.Item>
              )}
            </Descriptions>
          </>
        ) : (
          <>
            <Paragraph>Not registered in PEPS</Paragraph>
            {statusStore.pepsUser && statusStore.pepsUser.role === "DOCTOR" && (
              <Button
                type="primary"
                onClick={() => setRegisterModalVisible(true)}
              >
                Register
              </Button>
            )}

            <PepsModal
              url={url}
              visible={registerModalVisible}
              onMessage={onRegisterMessageEvent}
              onCancel={() => setRegisterModalVisible(false)}
            />
          </>
        )}
      </Col>
    </Row>
  )
}

export default observer(PatientsListDetails)
