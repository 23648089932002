import React from "react"
import { Alert, Button, Divider, List, Modal, Tree, Typography } from "antd"
import { SUPPORT_EMAIL } from "config/env"
import styled from "styled-components"
import { InstructionData } from "../../../models/InstructionData"

const { Link, Text, Paragraph } = Typography

interface SyncRepliesInstructionModalProps {
  visible: boolean
  instructionData: InstructionData
  onRetry: (e: React.MouseEvent<HTMLElement>) => void
  onCancel: (e: React.MouseEvent<HTMLElement>) => void
}

const StyledOL = styled.ol`
  .ant-typography & {
    list-style-type: lower-alpha;
  }
`

const SyncRepliesInstructionModal: React.FC<SyncRepliesInstructionModalProps> =
  ({ visible, instructionData, onRetry, onCancel }) => {
    const { incorrectClarumFields, clarumURLs, summary } = instructionData

    const onDetailsClick = () => {
      const treeData = Object.entries(summary).map(
        ([customCSSClass, errors], classIndex) => ({
          title: <Text strong>{customCSSClass}</Text>,
          key: classIndex,
          children: errors.map((error, errorIndex) => ({
            title: <Alert message={error} type="error" />,
            key: `${classIndex}-${errorIndex}`,
          })),
        }),
      )

      Modal.error({
        title: "Sync error",
        width: 700,
        content: (
          <>
            <Paragraph>Fields with error:</Paragraph>
            <Tree
              defaultExpandAll={true}
              selectable={false}
              showLine={{ showLeafIcon: false }}
              treeData={treeData}
            />
          </>
        ),
      })
    }

    return (
      <Modal
        title="Problem in Clarum"
        visible={visible}
        okText="Check again"
        onOk={onRetry}
        onCancel={onCancel}
        destroyOnClose={true}
      >
        <Paragraph>
          <Alert
            type="warning"
            showIcon
            message="There are missing/incorrect fields for some in Clarum to store some answers."
            description={
              <>
                <Paragraph>
                  These fields cannot be created automatically
                </Paragraph>
                <Button onClick={onDetailsClick}>Show error details</Button>
              </>
            }
          />
        </Paragraph>
        <Paragraph>Please proceed with the instruction below:</Paragraph>
        <List
          dataSource={[
            <>
              Step 1. Go to Clarum setting,{" "}
              <Link href={clarumURLs.pepsFrame} target="_blank">
                PEPS frame
              </Link>{" "}
              in patient section.
            </>,
            <>
              Step 2. Add the following fields:
              <StyledOL>
                {incorrectClarumFields.map((field) => (
                  <li key={field.customCSSClass}>
                    Label:{" "}
                    <Text strong copyable>
                      {field.label}
                    </Text>{" "}
                    (proposed),
                    <br />
                    Type: <Text strong>text</Text>,<br />
                    Custom CSS class:{" "}
                    <Text strong copyable>
                      {field.customCSSClass}
                    </Text>
                    .
                  </li>
                ))}
              </StyledOL>
            </>,
            <>
              Step 3. Go to{" "}
              <Link href={clarumURLs.apiSettings} target="_blank">
                PEPS/Clarum API settings
              </Link>{" "}
              in API section.
            </>,
            <>
              Step 4. Grant "Read", "Create" and "Update" access to newly
              created fields:
              <StyledOL>
                {incorrectClarumFields.map((field) => (
                  <li key={field.customCSSClass}>
                    For <Text strong>{field.label}</Text>
                    <br />
                    select <Text strong>Read</Text>, <Text strong>Create</Text>{" "}
                    and <Text strong>Update</Text>.
                  </li>
                ))}
              </StyledOL>
            </>,
            'Step 5. Click on "Save API" button and wait for changes to be saved.',
            <>
              Step 6 (optional). Go to{" "}
              <Link href={clarumURLs.advancedSearchSettings} target="_blank">
                Advanced search settings
              </Link>{" "}
              and add fields which will be used later for filtering list of
              patients.
            </>,
            'Step 7. Click "Check again" below to reconnect to Clarum and verify changes.',
          ]}
          renderItem={(item) => (
            <List.Item>
              <Text>{item}</Text>
            </List.Item>
          )}
        />
        <Alert
          type="warning"
          message='Changes in API settings may not be visible immediately.
          If you still see this information, please waiting for a few minutes and
          retry by clicking "check again" button.'
          showIcon
        />
        <Divider />
        <Paragraph>
          If you have any questions, please{" "}
          <Link href={`mailto:${SUPPORT_EMAIL}`}>contact us</Link>.
        </Paragraph>
      </Modal>
    )
  }

export default SyncRepliesInstructionModal
