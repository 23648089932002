import React from "react"
import { DataNode } from "rc-tree/lib/interface"
import { Reply, ReplyAnswer } from "../../../models/Reply"
import { Typography } from "antd"
import { MessageInfo } from "../../../models/MessageInfo"

const { Text } = Typography

type IndexedMessage = {
  subject: string
  questions: ReplyAnswer[]
  repliesCount: number
}

function generateQuestionsDataForTree(replies: Reply[]): DataNode[] {
  const indexedMessages = new Map<string, IndexedMessage>()

  replies.forEach((reply) => {
    const pepsId = reply.for.pepsId
    if (!pepsId) {
      return // PEPS Node is running in standalone mode.
    }
    const indexedMessage = indexedMessages.get(pepsId)
    if (indexedMessage) {
      indexedMessage.repliesCount += 1
    } else {
      indexedMessages.set(pepsId, {
        subject: reply.for.subject,
        questions: Object.keys(reply.answers) as ReplyAnswer[],
        repliesCount: 1,
      })
    }
  })

  return [
    {
      title: "All messages",
      key: "all",
      children: Array.from(indexedMessages).map(([pepsId, message]) => ({
        title: (
          <>
            <Text strong>{message.subject}</Text>{" "}
            <Text>
              ({message.repliesCount}{" "}
              {message.repliesCount > 1 ? "replies" : "reply"})
            </Text>
          </>
        ),
        key: pepsId,
        children: message.questions.map((question) => ({
          title: <Text italic>{question}</Text>,
          key: `${pepsId}|${question}`,
        })),
      })),
    },
  ]
}

function prepareSelectedMessagesList(checkedKeys: string[]): MessageInfo[] {
  const messagesIndexed = new Map<string, string[]>()
  checkedKeys.forEach((key) => {
    const separatorIndex = key.indexOf("|")
    if (key === "all" || separatorIndex === -1) {
      return
    }

    const pepsId = key.substr(0, separatorIndex)
    const question = key.substr(separatorIndex + 1)

    const message = messagesIndexed.get(pepsId)
    if (message) {
      message.push(question)
    } else {
      messagesIndexed.set(pepsId, [question])
    }
  })

  return Array.from(messagesIndexed.entries()).map(([pepsId, questions]) => ({
    pepsId,
    questions,
  }))
}

export { generateQuestionsDataForTree, prepareSelectedMessagesList }
