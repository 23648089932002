import React, { useContext, useState } from "react"
import { Button } from "antd"

import { Patient } from "../../../models/Patient"
import RootStore from "stores/RootStore"
import { CheckOutlined, SyncOutlined } from "@ant-design/icons"
import Modals from "services/Modals/Modals"

interface PatientsListRefreshStatusesProps {
  patients: Patient[] | undefined
}

const REFRESHING_COMPLETE_TIMEOUT = 2000

const PatientsListRefreshStatuses: React.FC<PatientsListRefreshStatusesProps> =
  ({ patients }) => {
    const [isRefreshingStatuses, setIsRefreshingStatuses] = useState(false)
    const [isRefreshingComplete, setIsRefreshingComplete] = useState(false)
    const { patientsStore, statusStore } = useContext(RootStore)

    const isDoctor =
      statusStore.pepsUser && statusStore.pepsUser.role === "DOCTOR"

    async function refreshStatuses() {
      if (!patients) return

      setIsRefreshingComplete(false)
      setIsRefreshingStatuses(true)

      await (isDoctor
        ? patientsStore.refreshStatusesOfOwn()
        : patientsStore.refreshAllStatuses()
      ).catch((error) => {
        Modals.showError("Cannot refresh statuses", error.message)
      })

      setIsRefreshingComplete(true)
      setIsRefreshingStatuses(false)

      setTimeout(() => {
        setIsRefreshingComplete(false)
      }, REFRESHING_COMPLETE_TIMEOUT)
    }

    return (
      <Button
        loading={isRefreshingStatuses}
        disabled={!patients}
        size="small"
        onClick={refreshStatuses}
        icon={isRefreshingComplete ? <CheckOutlined /> : <SyncOutlined />}
      >
        Refresh Statuses
      </Button>
    )
  }

export default PatientsListRefreshStatuses
