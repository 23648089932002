import React, { useState } from "react"
import { Button } from "antd"
import { Patient } from "../../../models/Patient"
import { SwapOutlined } from "@ant-design/icons"
import SyncRepliesModal from "components/SyncRepliesModal/SyncRepliesModal.component"

interface SyncRepliesProps {
  patients: Patient[] | undefined
}

const SyncRepliesButton: React.FC<SyncRepliesProps> = ({ patients }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  return (
    <>
      <Button
        disabled={!patients}
        type="primary"
        size="small"
        onClick={() => setIsModalVisible(true)}
        icon={<SwapOutlined />}
      >
        Sync replies
      </Button>

      {patients && (
        <SyncRepliesModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </>
  )
}

export default SyncRepliesButton
