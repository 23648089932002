import React from "react"

import { createAvatar } from "@dicebear/avatars"
import * as style from "@dicebear/avatars-avataaars-sprites"
import styled from "styled-components"
import { Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"

interface PepsAvatarProps {
  seed: string | undefined
  size?: "small" | "default"
}

// Config - copied from PEPS Node
const AVATAAARS_CONFIG: style.Options = {
  // Whitelist of all style options to make sure we will not see something not appropriate after package update.
  // nok eyepatch
  top: [
    "longHair",
    "shortHair",
    /* 'eyepatch', */ "hat",
    "hijab",
    "turban",
    "bigHair",
    "bob",
    "bun",
    "curly",
    "curvy",
    "dreads",
    "frida",
    "fro",
    "froAndBand",
    "miaWallace",
    "longButNotTooLong",
    "shavedSides",
    "straight01",
    "straight02",
    "straightAndStrand",
    "dreads01",
    "dreads02",
    "frizzle",
    "shaggy",
    "shaggyMullet",
    "shortCurly",
    "shortFlat",
    "shortRound",
    "shortWaved",
    "sides",
    "theCaesar",
    "theCaesarAndSidePart",
    "winterHat01",
    "winterHat02",
    "winterHat03",
    "winterHat04",
  ],
  accessories: [
    "kurt",
    "prescription01",
    "prescription02",
    "round",
    "sunglasses",
    "wayfarers",
  ],
  facialHair: ["medium", "light", "majestic", "fancy", "magnum"],
  clothes: [
    "blazer",
    "blazerAndShirt",
    "blazerAndSweater",
    "sweater",
    "collarAndSweater" /* 'shirt', 'graphicShirt', */,
    "shirtCrewNeck",
    "shirtScoopNeck",
    "shirtVNeck",
    "hoodie",
    "overall",
  ],
  // cry dizzy xDizzy
  eyes: [
    "close",
    "closed",
    /* 'cry', / 'default', /* 'dizzy', 'xDizzy', */ "roll",
    "eyeRoll",
    "happy" /* 'hearts', */,
    "side",
    "squint",
    "surprised",
    "wink",
    "winkWacky",
  ],
  eyebrow: [
    /* 'angry', 'angryNatural', */ "default",
    "defaultNatural",
    "flat",
    "flatNatural",
    "raised",
    "raisedExcited",
    "raisedExcitedNatural" /* 'sad', 'sadConcerned', 'sadConcernedNatural', 'unibrow', 'unibrowNatural', */,
    "up",
    "upDown",
    "upDownNatural",
    "frown",
    "frownNatural",
  ],
  // concerned disbelief eating grimace sad scream screamOpen tongue vomit
  mouth: [
    /* 'concerned', */ "default",
    /* 'disbelief', 'eating', 'grimace', 'sad', 'scream', 'screamOpen', */ "serious",
    "smile",
    /* 'tongue', */ "twinkle" /* 'vomit', */,
  ],
}

const StyledDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  margin-top: -5px;
  color: #bdbdbd;
  background: #eeeeee;
  border-radius: 50%;
  overflow: hidden;

  &.avatar-small {
    width: 24px;
    height: 24px;
  }
`

const StyledAvatar = styled(Avatar)`
  margin-top: -5px;
`

const avatarsCache = new Map<string, string>()
const CACHE_LIMIT = 200

const PepsAvatar: React.FC<PepsAvatarProps> = ({ seed, size }) => {
  function getAvatar(): string | null {
    if (seed) {
      const avatarSvg = avatarsCache.get(seed)
      if (avatarSvg) return avatarSvg

      const newAvatarSvg = createAvatar(style as any, {
        seed,
        ...AVATAAARS_CONFIG,
      })
      avatarsCache.set(seed, newAvatarSvg)

      limitCache()

      return newAvatarSvg
    }
    return null
  }

  function limitCache(): void {
    const itemsOverLimit = avatarsCache.size - CACHE_LIMIT
    if (itemsOverLimit > 0) {
      const keysToRemove = Array.from(avatarsCache.keys()).slice(
        0,
        itemsOverLimit,
      )
      keysToRemove.forEach((key) => avatarsCache.delete(key))
    }
  }

  const avatarSvg = getAvatar()
  if (avatarSvg) {
    return (
      <StyledDiv
        className={`avatar-${size}`}
        dangerouslySetInnerHTML={{ __html: avatarSvg }}
      />
    )
  }

  return <StyledAvatar size={size} icon={<UserOutlined />} />
}

export default PepsAvatar
