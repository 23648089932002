import { Patient } from "../../../models/Patient"
import { PEPS_URL, SERVER_URL } from "config/env"
import StringUtils from "services/StringUtils/StringUtils.service"
import PatientsStore from "stores/PatientsStore/PatientsStore"
import StatusStore from "stores/StatusStore/StatusStore"
import { autorun } from "mobx"
import BackEndApi from "services/BackEndApi/BackEndApi.service"

export type ParsedResponse = unknown
export type RetryWith = { url: RequestInfo; requestOptions: RequestInit }

let patientsStore: PatientsStore | undefined
let statusStore: StatusStore | undefined

class PepsApi {
  static init(_patientsStore: PatientsStore, _statusStore: StatusStore): void {
    patientsStore = _patientsStore
    statusStore = _statusStore

    statusStore.requestLoginToPeps()
  }

  static async checkStatus(): Promise<void> {
    return await fetch(`${PEPS_URL}/api/status`)
      .then((response) => response.text())
      .then((data) => {
        if (data !== "ok") throw new Error(`PEPS response: ${data}`)
      })
  }

  static async authorizedFetch(
    url: string,
    requestOptions: RequestInit = {},
  ): Promise<ParsedResponse> {
    const newOptions = {
      ...requestOptions,
      headers: {
        ...requestOptions!.headers,
        Authorization: `Bearer ${window.localStorage.getItem(
          "identificationToken",
        )}`,
      },
    }
    return fetch(url, newOptions).then((response) =>
      PepsApi.handleResponse(response, {
        url,
        requestOptions: newOptions as RequestInit,
      }),
    )
  }

  static async handleResponse(
    response: Response,
    retryWith?: RetryWith,
  ): Promise<ParsedResponse> {
    const contentType = response.headers.get("content-type")
    const content =
      contentType && contentType.indexOf("application/json") !== -1
        ? await response.json()
        : await response.text()

    if (
      !response.ok &&
      (response.status === 401 || response.status === 440) &&
      retryWith
    ) {
      return PepsApi.loginAndRetry(retryWith)
    }

    if (!response.ok) {
      throw new Error(content)
    }

    return content
  }

  static async loginAndRetry(retryWith: RetryWith): Promise<ParsedResponse> {
    return PepsApi.login(true).then(() => {
      // update Token
      const requestOptionsWithNewToken = {
        ...retryWith.requestOptions,
        headers: {
          ...retryWith.requestOptions.headers,
          ...{
            Authorization: `Bearer ${window.localStorage.getItem(
              "identificationToken",
            )}`,
          },
        },
      }
      return fetch(retryWith.url, requestOptionsWithNewToken).then((newRes) =>
        PepsApi.handleResponse(newRes),
      )
    })
  }

  static async waitForLogin(): Promise<void> {
    return new Promise((resolve, reject) => {
      const disposer = autorun(() => {
        if (!statusStore!.loginToPepsRequested && !statusStore!.pepsUser) {
          disposer()
          reject(new Error("You are not logged in to PEPS."))
        }

        if (statusStore!.pepsUser && !statusStore!.retryLoginRequested) {
          disposer()
          resolve()
        }
      })
    })
  }

  static async login(isRetrying = false): Promise<void> {
    if (isRetrying) {
      // We avoid resetting anything, because an action in any component depending on state might be ongoing.
      statusStore!.requestRetryLogin()
    } else {
      // Do not reset patientsStore, because it will stuck in a login loop.
      patientsStore!.reset()
      statusStore!.pepsUserHasLoggedOut()
      statusStore!.requestLoginToPeps()
    }
    return PepsApi.waitForLogin()
  }

  static async logout(): Promise<void> {
    await BackEndApi.authorizedFetch(`${SERVER_URL}api/users/revoke-token`, {
      method: "POST",
    }).catch(() => {
      /* noop */
    })
    statusStore!.requestLogoutFromPeps()
    window.localStorage.removeItem("identificationToken")
    window.localStorage.removeItem("pepsUser")
    // reload() will be called in PepsLogoutModal.
  }

  static getRegisterFormUrl(patient: Patient): string {
    const url = `${PEPS_URL}/embedded/register`
    const queryParts = []

    const name = patient.name
    if (name) {
      const spaceInNameIndex = name.lastIndexOf(" ")
      if (spaceInNameIndex !== -1) {
        queryParts.push(
          `firstName=${encodeURIComponent(
            name.substring(0, spaceInNameIndex),
          )}`,
        )
        queryParts.push(
          `lastName=${encodeURIComponent(
            name.substring(spaceInNameIndex + 1),
          )}`,
        )
      }
      queryParts.push(
        `username=${encodeURIComponent(
          StringUtils.cleanString(name).replace(/\s/g, ".").toLowerCase(),
        )}`,
      )
    }

    if (patient.email) {
      queryParts.push(`email=${encodeURIComponent(patient.email)}`)
    }

    if (patient.language) {
      queryParts.push(`language=${encodeURIComponent(patient.language)}`)
    }

    return `${url}?${queryParts.join("&")}`
  }
}

export default PepsApi
