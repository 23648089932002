import React, { useEffect, useRef, useState } from "react"
import { PEPS_URL } from "config/env"
import styled from "styled-components"

const StyledIframe = styled.iframe`
  max-width: 100%;
`

interface PepsIframeProps {
  url: string
  onContentWidthUpdate: (width: number) => void
  onMessage?: (e: MessageEvent) => void
}

const DEFAULT_WIDTH = 500
const DEFAULT_HEIGHT = 300

const PepsIframe: React.FC<PepsIframeProps> = ({
  url,
  onContentWidthUpdate,
  onMessage,
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [width, setWidth] = useState<number>(DEFAULT_WIDTH)
  const [height, setHeight] = useState<number>(DEFAULT_HEIGHT)

  useEffect(() => {
    const handler = (event: MessageEvent): void => {
      // console.log("Message event:", event, iframeRef)

      if (
        !iframeRef ||
        !iframeRef.current ||
        !event.data ||
        event.origin !== PEPS_URL ||
        event.source !== iframeRef.current!.contentWindow
      )
        return

      // window resize
      const size = event.data.windowSize
      if (size) {
        if (size.width) {
          onContentWidthUpdate(size.width)
          setWidth(size.width)
        }

        if (size.height) {
          setHeight(size.height)
        }
      }

      if (onMessage) {
        onMessage(event)
      }
    }

    window.addEventListener("message", handler)

    // clean up
    return () => window.removeEventListener("message", handler)
  }, [onMessage, url, onContentWidthUpdate])

  return (
    <StyledIframe ref={iframeRef} src={url} width={width} height={height} />
  )
}

export default PepsIframe
