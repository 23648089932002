import { Patient, PatientStatusUpdate } from "../../../models/Patient"
import { SERVER_URL } from "config/env"
import PepsApi, {
  ParsedResponse,
  RetryWith,
} from "services/PepsApi/PepsApi.service"
import TypeUtils from "services/TypeUtils/TypeUtils.service"
import { Reply } from "../../../models/Reply"
import { InstructionData } from "../../../models/InstructionData"
import { MessageInfo } from "../../../models/MessageInfo"

class BackEndError extends Error {
  data: unknown
  code: number | undefined

  constructor(message: unknown, code?: number) {
    super(typeof message === "string" ? message : "No message")
    this.data = message
    if (typeof code !== "undefined") {
      this.code = code
    }
  }
}

class BackEndApi {
  static async getPatients(ownOnly: boolean): Promise<Patient[]> {
    const action = ownOnly ? "get-own" : "get"
    return await BackEndApi.authorizedFetch(
      `${SERVER_URL}api/patients/${action}`,
    ).then((res) => {
      if (!TypeUtils.recognizeNonNullObject(res) || !Array.isArray(res)) {
        throw new Error("Incorrect response")
      }

      return res as Patient[]
    })
  }

  static async refreshStatuses(
    ownOnly: boolean,
  ): Promise<PatientStatusUpdate[]> {
    const action = ownOnly ? "refresh-statuses-of-own" : "refresh-statuses"
    return await BackEndApi.authorizedFetch(
      `${SERVER_URL}api/patients/${action}`,
      { method: "POST" },
    ).then((response) => {
      if (
        !TypeUtils.recognizeNonNullObject(response) ||
        !Array.isArray(response)
      ) {
        throw new Error("Incorrect response")
      }
      return response
    })
  }

  static async registerPatient(
    patientId: string,
    pepsId: string,
    isCaregiver: boolean,
  ): Promise<string> {
    return await BackEndApi.authorizedFetch(
      `${SERVER_URL}api/patients/${patientId}/registered`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ pepsId, isCaregiver }),
      },
    ).then((res) => {
      if (
        !TypeUtils.recognizeNonNullObject(res) ||
        !TypeUtils.recognizeProperty(res, "pepsFrameId") ||
        typeof res.pepsFrameId !== "string"
      ) {
        throw new Error("Incorrect response")
      }

      return res.pepsFrameId
    })
  }

  static async getLatestReplies(): Promise<Reply[]> {
    return await BackEndApi.authorizedFetch(
      `${SERVER_URL}api/replies/get-latest`,
    ).then((res) => {
      if (!TypeUtils.recognizeNonNullObject(res) || !Array.isArray(res)) {
        throw new Error("Incorrect response")
      }
      return res as Reply[]
    })
  }

  static async transferReplies(
    messages: MessageInfo[],
  ): Promise<true | InstructionData> {
    return await BackEndApi.authorizedFetch(
      `${SERVER_URL}api/replies/transfer`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          messages,
        }),
      },
    ).then((res) => {
      if (res === "ok") {
        return true
      }
      if (!TypeUtils.recognizeNonNullObject(res)) {
        throw new Error("Incorrect response")
      }
      return res as InstructionData
    })
  }

  static async authorizedFetch(
    url: string,
    requestOptions: RequestInit = {},
  ): Promise<ParsedResponse> {
    const token = window.localStorage.getItem("identificationToken")
    const newOptions = {
      ...requestOptions,
      headers: {
        ...requestOptions!.headers,
        Authorization: `Bearer ${token}`,
      },
    }
    const retryWith = {
      url,
      requestOptions: newOptions as RequestInit,
    }

    if (!token) {
      return BackEndApi.loginAndRetry(retryWith)
    }

    return fetch(url, newOptions).then((response) => {
      return BackEndApi.handleResponse(response, retryWith)
    })
  }

  static async handleResponse(
    response: Response,
    retryWith?: RetryWith,
  ): Promise<ParsedResponse> {
    const contentType = response.headers.get("content-type")
    const content =
      contentType && contentType.indexOf("application/json") !== -1
        ? await response.json()
        : await response.text()
    if (
      !response.ok &&
      response.status >= 400 &&
      response.status <= 499 &&
      response.status !== 409 &&
      retryWith
    ) {
      return BackEndApi.loginAndRetry(retryWith)
    }

    if (!response.ok) {
      throw new BackEndError(content, response.status)
    }

    return content
  }

  static async loginAndRetry(retryWith: RetryWith): Promise<ParsedResponse> {
    return PepsApi.login(true).then(() => {
      // update Token
      const requestOptionsWithNewToken = {
        ...retryWith.requestOptions,
        headers: {
          ...retryWith.requestOptions.headers,
          ...{
            Authorization: `Bearer ${window.localStorage.getItem(
              "identificationToken",
            )}`,
          },
        },
      }
      return fetch(retryWith.url, requestOptionsWithNewToken).then((newRes) =>
        BackEndApi.handleResponse(newRes),
      )
    })
  }
}

export default BackEndApi

export { BackEndError }
